import { Button, List, ListItem, Grid, Typography } from '@mui/material'
import { UserContext } from 'base'
import { useDocumentTitle } from 'hooks'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

const StaffReleaseNotes = () => (
  <>
    <Typography variant="h4" mt={3}>
      Release Notes
    </Typography>
    <Typography variant="h6" mt={2}>
      Thu 6 Feb 2025
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated pricing formula for clothes and vinyl records.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Tue 14 Jan 2025
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added vinyl records to Box Labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Fri 20 Dec 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added vintage books to Box Labels and SKU Labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Sat 30 Nov 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added marketplace to Box Labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Wed 20 Nov 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added marketplace labels to SKU Labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Thu 14 Nov 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Renamed web app from "Thryft Clothes Crediting" to "Thryft OMS 2.0".
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Clothes Crediting Bot: Clothing items with no brand (assorted) are now
        automatically marked as rejected.
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Clothes Transfer Bot: Clothing items with no brand (assorted) can no
        longer be transferred online.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Thu 31 Oct 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Renamed old "SKU Labels" page to "Clothing Labels".
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added new "SKU Labels" page for printing labels for online records/CDs.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Wed 9 Oct 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added box labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Tue 1 Oct 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothes pricing formula to correct prices that are too high.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Sat 28 Sep 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothes pricing formula to increase prices across the board.
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Offline crediting clothing categories updated to follow the online
        categories:
        <List sx={{ listStyleType: 'circle', pl: 2, pt: 0, pb: 0 }} dense>
          <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
            For Women's Clothing:
            <List sx={{ listStyleType: 'disc', pl: 2, pt: 0, pb: 0 }} dense>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                New category: "Jumpsuits & Rompers"
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Sweaters" renamed to "Cardigans & Sweaters"
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Coats & Jackets" renamed to "Coats/Blazers/Jackets"
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Shorts" renamed to "Shorts & Skorts"
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
            For Men's Clothing.
            <List sx={{ listStyleType: 'disc', pl: 2, pt: 0, pb: 0 }} dense>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                New category: "Sweaters"
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Polos" removed (subsumed under "Shirts")
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Outerwear" renamed to "Coats/Blazers/Jackets"
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
            Existing credited clothing have been retroactively updated (no
            change to prices)
          </ListItem>
        </List>
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Sun 7 Jan 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated pricing formula.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Fri 5 Jan 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothes pricing and crediting formula.
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothing labels page with new prices.
      </ListItem>
    </List>
  </>
)

const SupervisorReleaseNotes = () => (
  <>
    <Typography variant="h4" mt={3}>
      Release Notes
    </Typography>
    <Typography variant="h6" mt={2}>
      Thu 6 Feb 2025
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated pricing formula for clothes and vinyl records.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Tue 14 Jan 2025
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added vinyl records to Box Labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Fri 20 Dec 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added vintage book labels to Box Labels and SKU Labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Sat 30 Nov 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added marketplace labels to Box Labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Wed 20 Nov 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added marketplace labels to SKU Labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Thu 14 Nov 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Renamed web app from "Thryft Clothes Crediting" to "Thryft OMS 2.0".
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Clothes Crediting Bot: Clothing items with no brand (assorted) are now
        automatically marked as rejected.
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Clothes Transfer Bot: Clothing items with no brand (assorted) can no
        longer be transferred online.
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Clothes Transfer Bot: Bug fix - Decimal points are now correctly
        supported in fabric blend details (e.g. "53.4% Cotton").
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Thu 31 Oct 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Renamed old "SKU Labels" page to "Clothing Labels".
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added new "SKU Labels" page for printing labels for online records/CDs.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Wed 9 Oct 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added box labels page.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Mon 7 Oct 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Staff and supervisor roles have been added. Staff can only access the
        "Labels" page, while supervisors can access all pages.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Wed 2 Oct 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Gift cards are now automatically created after trade-ins have been
        approved. The "Issue Gift Cards" page has therefore been removed.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Tue 1 Oct 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothes pricing formula to correct prices that are too high.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Sat 28 Sep 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothes pricing formula to increase prices across the board.
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Offline crediting clothing categories updated to follow the online
        categories:
        <List sx={{ listStyleType: 'circle', pl: 2, pt: 0, pb: 0 }} dense>
          <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
            For Women's Clothing:
            <List sx={{ listStyleType: 'disc', pl: 2, pt: 0, pb: 0 }} dense>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                New category: "Jumpsuits & Rompers"
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Sweaters" renamed to "Cardigans & Sweaters"
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Coats & Jackets" renamed to "Coats/Blazers/Jackets"
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Shorts" renamed to "Shorts & Skorts"
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
            For Men's Clothing.
            <List sx={{ listStyleType: 'disc', pl: 2, pt: 0, pb: 0 }} dense>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                New category: "Sweaters"
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Polos" removed (subsumed under "Shirts")
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
                "Outerwear" renamed to "Coats/Blazers/Jackets"
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
            Existing credited clothing have been retroactively updated (no
            change to prices)
          </ListItem>
        </List>
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Sun 7 Jan 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothes pricing formula.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Fri 5 Jan 2024
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Added clothes brand tier "4" (luxury).
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothes pricing and crediting formula.
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Updated clothing labels page with new prices.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Fri 13 Oct 2023
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Infinite scrolling and sorting by approval date, uploaded date, and
        collection date (newest first) added for completed uploads.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Mon 2 Oct 2023
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Login system added.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Sat 30 Sep 2023
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Fixed rounding errors with total crediting amount for clothes.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Thu 28 Sep 2023
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Clothes crediting formula updated.
      </ListItem>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Allow special characters (e.g. brackets) in clothes brand name.
      </ListItem>
    </List>
    <Typography variant="h6" mt={2}>
      Tue 19 Sep 2023
    </Typography>
    <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
      <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
        Emails for donations are now supported for clothes crediting.
      </ListItem>
    </List>
  </>
)

const Home = () => {
  useDocumentTitle()
  const user = useContext(UserContext)

  return (
    <>
      <Typography variant="h3">Thryft OMS 2.0</Typography>
      <Grid container mt={1} spacing={1}>
        <Grid container item xs textAlign="center">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/change-password"
            sx={{ height: '100%' }}
          >
            Change Password
          </Button>
        </Grid>
        <Grid container item xs="auto">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/logout"
            sx={{ height: '100%' }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
      {user.isSupervisorOrAbove ? (
        <SupervisorReleaseNotes />
      ) : (
        <StaffReleaseNotes />
      )}
    </>
  )
}

export default Home
